import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';

import {MailingListForm} from 'components/Forms';

// import Button from 'components/Global/Button';

import PageWrapper from '../styles/vegas.css.js';

const Leagues = (props) => {

  return (
    <PageWrapper>
      <main className="vegas-content">
        <section className="header">
          <img className="logo" src={'https://webcdn.hirezstudios.com/smite-esports/assets/s2v-logo.png'}/>
        </section>
        
        <div className="center-wrapper">
          <section className="about">
            <h1>JANUARY 17-19</h1>
            <h2>HYPERX ARENA LAS VEGAS</h2>
            <h5>The SMITE 2 Founders Series will be kicking off September 14th, leading up to the first live SMITE 2 event in the HyperX Arena Las Vegas on January 17-19, 2025.</h5>
            <h6>Tickets go on sale September 13th, 2024 at 8:00AM PT/11:00AM ET/5:00PM CEST!</h6>
            <div className="ticket-info neonBorder">
              <div className="early-bird">
                <h5>SMITE 2 Founder's Series at HyperX Arena Las Vegas General Admission Ticket - $379</h5>
              </div>
              <p>General Admission Tickets include:</p>
              <ul>
                <li>3-Day Admission</li>
                <li>Event T-shirt</li>
                <li>Twitch Drops*</li>
                <li>Darkheart Zeus** in SMITE 2</li>
              </ul>
              <div>
              <a class="ticket-link" href="https://www.eventbrite.com/e/smite-2-founders-series-at-hyperx-arena-las-vegas-tickets-1002529351277" target="_blank" rel="noreferrer">
                <p className="neonRed">Buy Tickets</p>
              </a>
              <div className="ticket-note">* Twitch Drops information will be available at a later date.</div>
              <div className="ticket-note">** Darkheart Zeus is a Classic Skin in SMITE 2 and will be made purchasable in-game for Legacy Gems.</div>
              </div>
            </div>
            <p class="ticket-agreement"><a href="https://webcdn.hirezstudios.com/smite-esports/assets/Vegas-Founder's-Series-2025-Ticket-Agreement-(Sep-2024).pdf">SMITE 2 Founder’s Series Ticket Agreement</a></p>
          </section><section className="about">
          </section>
        </div>
      </main>
    </PageWrapper>
  );
};

export default withContext(Leagues);
